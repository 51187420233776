import HondaCBF from "../Assets/HondaCBF.png";
import APIDocs from "../Assets/PDFs/APIDocs.pdf";
import APIS from "../Assets/APIS.png";
import AWS from "../Assets/awsppt.png";
import AWS2 from "../Assets/PDFs/awsppt.pdf";
import QuickR from "../Assets/quickr.png";
import QuickRPDF from "../Assets/PDFs/HondaCBF125OnePager.pdf";
import BusinessCase from "../Assets/PDFs/BusinessCase.pdf";
import BusinessCaseImg from "../Assets/BusinessCase.png";
import IoTProcess from "../Assets/IoT Sensors.png";
import ChatterMealData from "../Assets/PDFs/DataAnalysis.pdf";
import NPSDataImg from "../Assets/NPSAvg.png";
import ZeroTrust from "../Assets/PDFs/ZeroTrustModel.pdf";
import ZeroTrustImg from "../Assets/ZeroTrustImg.png";
import PlateUpAuditReport from "../Assets/PDFs/Plate Up Report.pdf";
import PlateUpReportImg from "../Assets/reportfrontpage.png";
import PlateUpJourneyImg from "../Assets/PlateUpMap.png";
import PlateUpJourney from "../Assets/PDFs/Plate Up Customer Journey Map.pdf";

export const CaseStudyData = [
  {
    id: 1,
    title: "Instructional Manual: Honda CBF125",
    description:
      "Created a comprehensive instructional manual for the Honda CBF125 motorcycle. ",
    toolsUsed: ["SharePoint", "Adobe Illustrator"],
    challenge:
      "New motorcycle owners, particularly those with minimal mechanical experience, often rely on professional services for routine maintenance. However, if these new riders have bought the motorbike second-hand, they will attempt to complete the service themselves. The challenge was to empower these riders, to confidently perform an oil change, safely.",
    solution:
      "Developed a comprehensive, easy-to-follow DIY oil change guide tailored for Honda CBF125 owners. The guide included detailed instructions, safety tips, and a list of tools required for the task. Visual aids like step-by-step images were incorporated to enhance understanding and ensure accuracy throughout the process.",
    detail: [
      "How to change your tyres",
      "How to fix a puncture on the road",
      "How to properly clean your motorcycle chain",
      "How to replace the brake fluid in your motorcycle",
      "Inspection of the Motorcycle before your journey",
      "How to change your headlights",
      "How to adjust your steering bearings",
      "12 Common Issues and Diagnostics",
      "How to replace the air cleaner",
      "Riding to increase miles to gallon",
    ],
    roles: [
      "Researched and compiled detailed instructions for essential motorcycle maintenance tasks, simplifying information for beginner riders.",
      "Created a user-friendly instructional manual layout incorporating pictures to enhance understanding and user experience.",
    ],
    imageURL: HondaCBF,
    samplePDF: "",
    tag: ["CDD: Technical Writing"],
    audience: ["Beginner Motorcycle Riders"],
    deliverables: ["Instructional Manual"],
  },
  {
    id: 2,
    title: "API Documentation: Data-Business Element Matcher",
    description:
      "Developed comprehensive API documentation for the Data Matcher platform, a greenfield project.",
    toolsUsed: ["Confluence", "Markdown"],
    challenge:
      "Develop comprehensive API documentation for a greenfield project; Data Matcher platform, ensuring it is both informative and accessible. Data Matcher platform allows the users to verify the automated matches between the business and data elements. Key audience for the platform are data owners and business analysts who will be validating the matched elements. The documentation needed to cater to both technical developers and business analysts.",
    solution:
      "Created a detailed API documentation set that breaks down the complexities of the Data Matcher system into clear, manageable parts. The documentation covers all functional aspects of the system, from basic setups to endpoints and configurations. Key features of the solution include:",
    detail: [
      "Detailed descriptions, parameters, and examples for each API endpoint, allowing users to understand and test functionalities directly from the documentation",
      "Included practical examples and code snippets in Python and JavaScript, showing how to interact with the API programmatically, tailored to real-world use cases that the end users frequently encounter.",
    ],
    roles: [
      "Worked closely with software developers to gain an understanding of the API features. This collaboration was essential in maintaining up-to-date and accurate documentation. Regular meetings and integration into the development process through SCRUM meetings allowed me to capture technical details firsthand and translate them into user-friendly language.",
      "Liaised with product managers and business stakeholders to ensure the documentation met all functional requirements and addressed user feedback effectively. This involved presenting draft documents, collecting feedback, and iterating on the content to improve clarity and usability.",
      "Organised and maintained the documentation repository using Confluence that enabled team members to peer review the documentation and make comments for updates.",
    ],
    imageURL: APIS,
    samplePDF: APIDocs,
    tag: ["CDD: Technical Writing"],
    audience: ["Data Owners", "Business Analysts", "Developers"],
    deliverables: ["API Documentation"],
  },
  {
    id: 3,
    title: "Educational Material: AWS S3 Solutions Architect Associate",
    description:
      "Created an engaging slide deck that simplifies AWS S3 concepts for aspiring Solutions Architect Associates. ",
    toolsUsed: ["AWS S3", "AWS Documentation", "Canva"],
    challenge:
      "Create an engaging and informative educational deck on AWS S3 that is comprehensive enough to aid in passing the AWS certification exam, while being accessible enough for beginners in cloud storage concepts.",
    solution:
      "Developed an educational slide deck that distills the complexity of AWS S3 into clear, digestible segments, each focusing on core aspects such as S3 buckets, objects, storage classes, and lifecycle management. Key features of the solution include:",
    detail: [
      "A hands-on demonstration within AWS S3, creating buckets and managing storage lifecycle policies to ensure practical understanding.",
      "Visual aids and examples in the slides to simplify complex concepts, making them accessible for beginners.",
      "Real-world scenarios tailored to the end-user, providing guidance on applying concepts to actual use cases pertinent to their roles.",
    ],
    roles: [
      "Researched and compiled accurate information on AWS S3, including its core functionalities, storage classes, and lifecycle management.",
      "Crafted an engaging presentation structure that simplifies complex concepts into digestible segments tailored for beginners.",
      "Created compelling visuals (using Canva) such as diagrams, infographics, and illustrations to enhance comprehension and user engagement.",
    ],
    imageURL: AWS,
    samplePDF: AWS2,
    tag: ["CDD: Research & Writing"],
    audience: [
      "AWS Solutions Architect Associate Candidates",
      "Cloud Computing Beginners",
    ],
    deliverables: ["Educational Slide Deck"],
  },
  {
    id: 4,
    title: "Quick Reference Guide: Honda CBF125",
    description:
      "Empowering new riders! Created a user-friendly, one-page PDF quick reference guide for Honda CBF125 motorcycle owners.",
    toolsUsed: ["Adobe Illustrator"],
    challenge:
      "Design a user-friendly one-page PDF reference guide for new Honda CBF125 motorcycle riders. The guide should empower them to perform basic maintenance tasks between professional servicing.",
    solution:
      "Developed a clear and concise one-page PDF guide tailored specifically for beginner riders who have just completed their Compulsory Basic Training (CBT). The guide provides easy-to-understand information on essential maintenance procedures. Key features include:",
    detail: [
      "Step by step guide with pictures for cleaning the chain, a common maintenance task for new riders",
      "Recommendations for suitable products to enhance the cleaning process.",
    ],
    roles: [
      "Compiled clear and concise instructions for essential motorcycle maintenance tasks, simplifying information for beginner riders.",
      "Created a user-friendly one-page PDF layout incorporating pictures to enhance understanding and user experience.",
      "Researched and included recommendations for suitable products related to the maintenance procedures.",
    ],
    imageURL: QuickR,
    samplePDF: QuickRPDF,
    tag: ["CDD: Technical Writing"],
    audience: ["New Motorcycle Riders", "CBT Riders", "Motorcycle Enthusiasts"],
    deliverables: ["Quick Reference Guide"],
  },

  {
    id: 5,
    title: "Business Case: Reducing Churn for Company X",
    description:
      "Analysed customer NPS survey data to create a compelling business case for a meal kit delivery company.",
    toolsUsed: ["Python Jupyter Notebook", "Canva"],
    challenge:
      "ABC Ltd wanted a business case created for a meal kit delivery company (Company X) who was struggling with high customer churn and needed a strategy to reduce it by 25%. Company X had conducted a Net Promoter Score (NPS) survey, but lacked the resources to analyse the data and develop an actionable plan. This is where ABC Ltd came in and wanted to develop a business case to pitch their services to Company X.",
    solution:
      "Utilised Python to analyse the NPS survey data and identify the preliminary reasons for churn. This analysis revealed interesting analysis within some segments of Company X's customer base. Developed a comprehensive business case, outlining the impact of churn and proposed ABC LTd's services as a next step solution. The business case included: ",
    detail: [
      "The challenge faced by Company X and the industry standards ",
      "Provided clear insights into the customer feedback and reasons for churn",
      "Highlighted the various services by ABC Ltd that could help Company X reduce churn by 25%",
      "Proposed a detailed plan of next steps and the expected outcomes of implementing the proposed solutions.",
    ],
    roles: [
      "Analysed the initial NPS survey data to identify trends and patterns related to customer churn.",
      "Created the business case to present the findings and propose solutions to reduce churn for Company X.",
      "Prepared questions for ABC Ltd of potential questions offered from Company X and how to address them.",
    ],
    imageURL: BusinessCaseImg,
    samplePDF: BusinessCase,
    tag: ["CDD: Technical Writing", "SO: Data Analysis"],
    audience: ["Business Executives", "Marketing Teams"],
    deliverables: ["Business Case"],
  },
  {
    id: 6,
    title: "Process Diagram: IoT sensors replacement process",
    description:
      "Outlined the steps involved in replacing IoT sensors within a building.",
    toolsUsed: ["Visio"],
    challenge:
      "Ensuring the smooth and efficient replacement of malfunctioning IoT sensors within a facility is crucial for maintaining optimal operational efficiency and data collection. However, several challenges can arise in this process, such as:",
    solution:
      "This process diagram provides a clear and structured approach to address these challenges and streamline the IoT sensor replacement process. Key benefits include: ",
    detail: [
      "Initiation: A business management team member, viewing the platform dashboard gets alerted to a sensor failure with potential cause to be a battery replacement.",
      "Sensor Evaluation: The designated facilities management representative evaluates the faulty sensor to determine the cause of failure and identify the appropriate replacement.",
      "Replacement Order: A replacement sensor is ordered if no sensors are available in the location, either through another location or the vendor.",
      "Sensor Deactivation & Removal: The faulty sensor is deactivated and removed from its location.",
      "Sensor Installation: The new sensor is installed following protocols and the facilities member ensures its secure mounting and connection, making a note of the manufacturer number.",
      "System Verification:The functionality of the new sensor is verified to ensure it's collecting and transmitting data correctly.",
      "Documentation & Notification: The new sensor number is updated on the Dashboard, where the business team member is now able to continue to monitor its data. Admin is notified of the successful replacement.",
    ],
    roles: [
      "Liaised with multiple stakeholders to understand the process of installing sensors, understanding the different roles available and their responsibilities.",
      "Designed and the process flow using Visio, reviewing and verifiying with stakeholders.",
    ],
    imageURL: IoTProcess,
    samplePDF: IoTProcess,
    tag: ["SO: Customer Journey Map", "SO: User Experience Testing"],
    audience: ["Facilities Management", "Administrative Team", "Team Manager"],
    deliverables: ["Process Diagrams", "User Journey Map"],
  },
  {
    id: 7,
    title: "Understand and Implementing Zero Trust Model",
    description:
      "Written an article on the Zero Trust Model for cyber security analysts.",
    toolsUsed: ["Notion"],
    challenge:
      "The Zero Trust Model is a security concept that has gained popularity in the cybersecurity industry. The challenge was to provide a comprehensive overview of the Zero Trust Model, its key principles, and how organisations can implement it to enhance their security posture. The article needed to be informative, engaging, and accessible to cybersecurity analysts and professionals.",
    solution:
      "I wrote an article that covered the following aspects of the Zero Trust Model:",
    detail: [
      "An overview of the Zero Trust Model and its key principles",
      "The benefits of implementing a Zero Trust approach",
      "Common issues when implementing Zero Trust Model",
      "Practical steps for implementing a Zero Trust Model in an organisation",
    ],
    roles: [
      "Researched the Zero Trust Model and its key principles to gain a comprehensive understanding of the topic.",
      "Developed an engaging and informative article that explained the Zero Trust Model in a clear and accessible manner.",
      "Provided diagrams to illustrate the Zero Trust Model.",
    ],
    imageURL: ZeroTrustImg,
    samplePDF: ZeroTrust,
    tag: ["CDD: Research & Writing", "CDD: Technical Writing"],
    audience: [
      "Cybersecurity Analysts",
      "IT Professionals",
      "Security Managers",
    ],
    deliverables: ["Article"],
  },
  {
    id: 8,
    title: "Data Analysis: Company ABC Ltd NPS Survey Data",
    description:
      "Analysed customer NPS survey data to provide actionable insights for Company ABC Ltd.",
    toolsUsed: ["Python"],
    challenge:
      "Company ABC Ltd had run an NPS survey from 2022 to 2023 and needed to understand the results of the survey to improve their services. The survey was conducted to understand the customer satisfaction levels and identify areas for improvement. The challenge was to analyse the survey data and provide actionable insights to Company ABC Ltd.",
    solution:
      "Using Python, I was able to gather valuable insights from the NPS survey data. The analysis included:",
    detail: [
      "Identifying the average NPS score for the company over the survey period",
      "Segmenting the data to identify trends in customer satisfaction levels",
      "Highlighting key areas of concern based on customer feedback",
      "Providing actionable recommendations to improve customer satisfaction",
    ],
    roles: [
      "Analysed the NPS survey data to identify trends and patterns related to customer satisfaction levels.",
      "Provided actionable insights to ABC Ltd based on the analysis.",
      "Prepared a detailed report outlining the key findings and recommendations for improvement.",
      "Presented the findings to the company stakeholders to discuss potential strategies for enhancing customer satisfaction.",
      "Discussed additional data sources that could be utilised to enhance the findings. ",
    ],

    imageURL: NPSDataImg,
    samplePDF: ChatterMealData,
    tag: ["SO: Data Analysis"],
    audience: ["Business Executives", "Marketing Manager"],
    deliverables: ["Data Analysis Report"],
  },
  {
    id: 9,
    title: "UAT Audit: Plate Up Mobile App",
    description:
      "Conducted a comprehensive User Acceptance Testing (UAT) audit for the Plate Up mobile application to ensure it meets user expectations and functional requirements before its market release.",
    toolsUsed: ["JIRA", "TestRail", "Microsoft Suite"],
    challenge:
      "Plate Up was preparing to launch its new mobile app and needed to ensure that the app was fully functional and met user expectations. The challenge was to conduct a thorough UAT audit to identify any usability issues, bugs, and gather user feedback to refine the application prior to launch.",
    solution:
      "Organized and led a series of UAT sessions, utilizing structured testing environments and real-world scenarios to simulate typical user behaviors. The testing process involved:",
    detail: [
      "Developing and executing test cases based on user stories to cover all app functionalities",
      "Gathering a diverse group of end-users to ensure a wide range of use cases are tested",
      "Identifying critical bugs and usability issues that could impact user satisfaction",
      "Collecting and analyzing user feedback to prioritize improvements",
      "Implementing a continuous feedback loop with the development team to quickly address and retest issues",
    ],
    roles: [
      "Coordinated with the product management team to outline the UAT strategy and objectives",
      "Managed the testing team to ensure thorough coverage of all features and scenarios",
      "Analyzed test results and user feedback to identify trends and areas for improvement",
      "Prepared a comprehensive UAT report detailing findings and recommended actions",
      "Presented findings to stakeholders, including technical teams and executive leadership, to inform the decision-making process and final app refinements",
    ],
    imageURL: PlateUpReportImg,
    samplePDF: PlateUpAuditReport,
    tag: ["SO: User Experience Testing"],
    audience: ["CEO", "Product Manager", "Development Team"],
    deliverables: [
      "UAT Audit Report",
      "Customer Journey Map",
      "User Stories",
      "Test Cases",
      "Test Plan",
      "Test Report",
    ],
  },
  {
    id: 10,
    title: "Customer Journey Mapping: Plate Up App Navigation",
    description:
      "Mapped the customer journey for the Plate Up from Ad Discover to Mobile App download to identify key touchpoints and enhance user experience throughout the app navigation.",
    toolsUsed: ["Google Analytics", "SurveyMonkey"],
    challenge:
      "With the goal of improving app usability and increasing user engagement, there was a need to map and analyse the customer journey to pinpoint areas where users experienced difficulties or drop-offs.",
    solution:
      "Conducted detailed user research and mapped out the customer journey from initial contact through to repeated use, focusing on identifying barriers to user conversion and retention.",
    detail: [
      "Analysed user interaction data from Google Analytics to understand where users spent most of their time and where they dropped off.",
      "Identified critical points in the journey where users faced confusion or dissatisfaction.",
      "Suggested design and functional improvements to streamline navigation and enhance user satisfaction.",
    ],
    roles: [
      "Led the project to map out and analyze the customer journey.",
      "Collaborated with UX/UI designers to interpret data and translate insights into actionable design improvements.",
      "Worked with the marketing team to adjust user onboarding based on findings from the journey map.",
      "Synthesized findings into a report for stakeholder review, highlighting key areas for improvement with actionable recommendations.",
      "Presented the final journey map and improvement plan to senior management and technical teams.",
    ],
    imageURL: PlateUpJourneyImg,
    samplePDF: PlateUpJourney,
    tag: ["SO: Customer Journey Map"],
    audience: ["Product Managers", "UI/UX Designers", "Marketing Team"],
    deliverables: [
      "Customer Journey Map",
      "User Feedback Analysis",
      "Improvement Plan Document",
    ],
  },
];
