import React from "react";
import { Box, Typography, Grid, useMediaQuery, useTheme } from "@mui/material";
import Lloyds from "../Assets/Lloyds.png";
import PlateUp from "../Assets/plateup.svg";
import AstraZ from "../Assets/astraz.png";
import BackToTop from "../Components/BackToTop";

const CustomersLogos = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "#6DACA1",
        minHeight: "250px",
        color: "#f5f5f5",
        position: "relative",
      }}
    >
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontSize: isSmallScreen ? "1.5rem" : "3rem" }}
      >
        Previous Clients
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={Lloyds}
            alt="Lloyds Banking Group logo"
            style={{ height: "90px", maxWidth: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={PlateUp}
            alt="PlateUp logo"
            style={{ height: "60px", maxWidth: "100%" }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={AstraZ}
            alt="AstraZeneca logo"
            style={{ height: "70px", maxWidth: "100%" }}
          />
        </Grid>
      </Grid>
      <BackToTop />
    </Box>
  );
};

export default CustomersLogos;
