import React from "react";
import Header from "./Components/Header";
import { Box } from "@mui/material";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          minHeight: `calc(100vh - 100px)`,
        }}
      >
        {children}
      </Box>
      {/* <Box className="footer" sx={{ position: "relative", bottom: 0 }}>
        <Footer />
      </Box> */}
    </>
  );
};

export default Layout;
