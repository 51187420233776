import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import Layout from "./Layout";
import LandingPage from "./Pages/LandingPage";
import Examples from "./Pages/Examples";
import Services from "./Pages/Services";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import ContactForm from "./Components/GetInTouch";
import CaseStudyDetail from "./Pages/CaseStudy";
import SampleWork from "./Pages/SampleWork";
import { CaseStudyData } from "./Components/CaseStudyData";

function App() {
  return (
    <>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/contact" element={<ContactForm />} />
              <Route path="/examples" element={<Examples />} />
              <Route path="/services" element={<Services />} />
              <Route
                path="/samplework"
                element={<SampleWork caseStudies={CaseStudyData} />}
              />
              <Route
                path="/case-study/:projectId"
                element={<CaseStudyDetail />}
              />
            </Routes>
          </Layout>
        </ThemeProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
