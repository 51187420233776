import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { CaseStudyData } from "../Components/CaseStudyData";
import { Box, Typography, Grid, Container, Button } from "@mui/material";

const CaseStudyDetail = () => {
  const { projectId } = useParams();
  const project = CaseStudyData.find((p) => p.id === Number(projectId));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!project) {
    return (
      <Typography variant="h6" color="error">
        Project not found.
      </Typography>
    );
  }

  const firstDeliverableName = project.deliverables
    ? project.deliverables[0]
    : "Details";

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <Link
          to="/samplework"
          style={{ textDecoration: "none", color: "#cecece" }}
        >
          <Typography color="#AAAAAA">&lt; Back to Sample Work</Typography>
        </Link>
      </Box>
      <Typography variant="h4" fontWeight="bold" color="#271542" gutterBottom>
        {project.title}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <img
            src={project.imageURL}
            alt="Project Visual"
            style={{ width: "100%", height: "auto", borderRadius: "8px" }}
          />
          {/* Box for additional content under the image */}
          <Box sx={{ mt: 2, bgcolor: "#f5f5f5", p: 2, borderRadius: "4px" }}>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Deliverables
            </Typography>

            <ul>
              {project.deliverables.map((deliverable, index) => (
                <li key={index}>{deliverable}</li>
              ))}
            </ul>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Audience
            </Typography>

            <ul>
              {project.audience.map((audience, index) => (
                <li key={index}>{audience}</li>
              ))}
            </ul>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
              Tools Used
            </Typography>
            <ul>
              {project.toolsUsed.map((tool, index) => (
                <li key={index}>{tool}</li>
              ))}
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" fontWeight="bold">
            Challenge:
          </Typography>
          <Typography paragraph>{project.challenge}</Typography>
          <Typography variant="h6" fontWeight="bold">
            Solution:
          </Typography>
          <Typography paragraph>{project.solution}</Typography>
          {project.detail && (
            <ul>
              {project.detail.map((detail, index) => (
                <li key={index}>{detail}</li>
              ))}
            </ul>
          )}
          <Typography variant="h6" fontWeight="bold">
            Roles & Responsibilities:
          </Typography>
          <ul>
            {project.roles &&
              project.roles.map((role, index) => <li key={index}>{role}</li>)}
          </ul>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            mt: 2,
            p: 2,
            bgcolor: "white",
            border: "2px solid #f0f0f0",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography variant="h6">Sample Output</Typography>

          {project.samplePDF ? (
            <Button
              variant="contained"
              href={project.samplePDF}
              target="_blank"
              sx={{
                textDecoration: "none",
                backgroundColor: "#271542",
                "&:hover": { backgroundColor: "#d4af37" },
              }}
            >
              View {firstDeliverableName}
            </Button>
          ) : (
            <Typography variant="body1">Not available</Typography>
          )}
          <Typography variant="subtitle1">
            (Please Note: This is not the actual output but a sample due to
            confidentiality.)
          </Typography>
        </Box>
      </Grid>
    </Container>
  );
};

export default CaseStudyDetail;
