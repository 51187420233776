import React, { useState } from "react";
import { Typography, Box, useTheme, useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import BackToTop from "../Components/BackToTop";

const MyApproach = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = 5;

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.activeIndex);
  };

  return (
    <Box
      id="myapproach"
      sx={{
        p: 4,
        backgroundColor: "#f5f5f5",
        color: "#271542",
        minHeight: "500px", // Increase the height of the container
        margin: "auto", // Center the container
        position: "relative", // Ensure the container is relative for absolute positioning of arrows
      }}
    >
      <style>
        {`
          .swiper-button-next, .swiper-button-prev {
            color: #271542; 
          }
        `}
      </style>
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontSize: isSmallScreen ? "1.5rem" : "3rem" }}
      >
        My Approach
      </Typography>

      <Typography variant="body1" align="center" gutterBottom sx={{ mb: 4 }}>
        I tailor my approach to suit your needs. Whether you want to be involved
        in every stage or only at the end, I can work flexibly.
      </Typography>
      <Typography variant="body1" align="center" gutterBottom sx={{ mb: 4 }}>
        I typically communicate with my clients via Slack or Teams, but flexible
        to your needs.{" "}
      </Typography>
      <Swiper
        modules={[Navigation]} // Ensure the Swiper component uses the Navigation module
        spaceBetween={50}
        slidesPerView={1}
        navigation={true} // Enables navigation arrows
        onSlideChange={handleSlideChange} // Update current slide index on slide change
        style={{ paddingBottom: "30px" }} // Add padding to the bottom for arrows
      >
        <SwiperSlide>
          <Box
            sx={{
              backgroundColor: "#d4af37",
              padding: "20px 70px",
              minHeight: "150px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Discovery
            </Typography>
            <Typography>
              We will kick things off with a discovery call to understand your
              project goals, target audience, and any existing documentation.
            </Typography>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            sx={{
              backgroundColor: "#d4af37",
              padding: "20px 70px",
              minHeight: "150px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Research and Analysis
            </Typography>
            <Typography>
              Based on your chosen service, I will conduct the necessary
              research, analyse your data, and develop a clear plan of action.
            </Typography>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            sx={{
              backgroundColor: "#d4af37",
              padding: "20px 70px",
              minHeight: "150px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              First Draft
            </Typography>
            <Typography>
              Once I have a solid foundation, I will create an initial draft for
              your review and incorporate your valuable feedback.
            </Typography>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            sx={{
              backgroundColor: "#d4af37",
              padding: "20px 70px",
              minHeight: "150px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Revisions and Refinement
            </Typography>
            <Typography>
              We will work collaboratively to refine and polish the draft until
              you are completely satisfied.
            </Typography>
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box
            sx={{
              backgroundColor: "#d4af37",
              padding: "20px 70px",
              minHeight: "150px",
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Delivery & Sign Off
            </Typography>
            <Typography>
              Once finalised, I will deliver the final assets and provide
              ongoing support to ensure a smooth launch (if applicable).
            </Typography>
          </Box>
        </SwiperSlide>
      </Swiper>

      <Typography align="center" sx={{ color: "lightgrey" }}>
        Current Slide: {currentSlide + 1} / {totalSlides}
      </Typography>
      <BackToTop />
    </Box>
  );
};

export default MyApproach;
