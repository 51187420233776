import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Poppins",
  },
  palette: {
    background: {
      default: "#f5f5f5",
    },
  },
});

export default theme;
