import { Typography, Grid, Paper } from "@mui/material";
import { Box, useMediaQuery, useTheme } from "@mui/system";
import React from "react";
import BackToTop from "../Components/BackToTop";
import ProfilePic from "../Assets/ProfilePic.JPG";

const AboutMe = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "#271542",
        color: "white",
        minHeight: "550px",
        position: "relative",
        paddingBottom: "50px",
      }}
    >
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontSize: isSmallScreen ? "1.5rem" : "3rem" }}
      >
        About Me
      </Typography>
      <Grid container spacing={4} sx={{ marginTop: "15px" }}>
        <Grid item xs={12} md={6}>
          <img
            src={ProfilePic}
            style={{ width: "300px", height: "auto" }}
            alt="Profile"
          />
          <Typography variant="body1">
            Leveraging expertise in both software engineering and management
            consulting, I help businesses optimise user experiences. My
            experience building platforms honed my technical skills, while as a
            Consultant, I advised clients of all sizes on enhancing the user
            experience through process optimisation, customer journey
            enhancement, data analysis and training their staff in new
            technologies.
          </Typography>
          <Typography variant="body1" sx={{ mt: "20px", fontWeight: "bolder" }}>
            Let me do the same for your business!
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            sx={{
              p: 2,
              backgroundColor: "white",
              color: "#271542",
              marginBottom: "15px",
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              Achievements
            </Typography>
            <Typography>
              Across my career, I have had the opportunity to work on a diverse
              range of projects across the Financial Services, Pharmaceuticals
              and Energy sectors, here are a few of my experiences:
            </Typography>
            <ul>
              <li>
                <strong>Onboarded 50K users to a space booking platform</strong>{" "}
                by creating user-friendly guides and training materials that
                ensured a smooth user experience.{" "}
              </li>

              <li>
                <strong>Mapped the user journey for a start-up</strong> {""}
                by identifying key touchpoints across different user personas.
                This helped the company refine their product strategy to better
                meet user needs.
              </li>

              <li>
                <strong>Created 30x 1-minute videos for software use</strong>{" "}
                making it easier for users to learn the software quickly and
                efficiently.
              </li>
              <li>
                <strong>
                  Developed a comprehensive training manual for the
                  configuration system
                </strong>{" "}
                empowering administrators with the knowledge to manage the
                system effectively.
              </li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
      <BackToTop />
    </Box>
  );
};

export default AboutMe;
