import React from "react";
import {
  Button,
  Grid,
  Typography,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Services from "./Services";
import CustomerLogos from "./CustomerLogos";
import Portfolio from "./Examples";
import Logo2 from "../Assets/Logo2.svg";
import AboutMe from "./AboutMe";
import MyApproach from "./MyApproach";

const LandingPage = () => {
  let navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#271542",
          py: isSmallScreen ? 2 : 4,
          px: isSmallScreen ? 2 : 3,
          color: "white",
        }}
      >
        <Grid container spacing={2} alignItems="center" justifyContent="center">
          <Grid
            item
            xs={12}
            sm={3.8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: isSmallScreen ? "11pt" : "13pt",
                textAlign: "left",
                padding: "5px",
              }}
            >
              Uncover Customer Insights: Mapping Behavior & Decoding Data.
            </Typography>
          </Grid>

          {!isSmallScreen && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "rgba(255,255,255,0.5)" }}
            />
          )}

          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              px: isSmallScreen ? 1 : 0,
            }}
          >
            <img
              src={Logo2}
              alt="Logo"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Grid>

          {!isSmallScreen && (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "rgba(255,255,255,0.5)" }}
            />
          )}

          <Grid
            item
            xs={12}
            sm={3.8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              px: isSmallScreen ? 1 : 2,
            }}
          >
            <Typography
              sx={{
                fontSize: isSmallScreen ? "11pt" : "13pt",
                textAlign: "left",
                flexGrow: 1,
                mt: 5.5,
              }}
            >
              Create content that resonates with your audience.
              {/* I bridge the gap between technical details and user understanding
              through engaging content. */}
            </Typography>

            <Button
              variant="contained"
              onClick={() => navigate("/samplework")}
              sx={{
                alignSelf: "flex-start",
                backgroundColor: "#6DACA1",
                pr: 3,
                mt: 2,
                justifyItems: "space-between",
                "&:hover": {
                  backgroundColor: "#d4af37",
                },
              }}
            >
              See examples
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box id="services" sx={{ flex: 1, position: "relative" }}>
        <Services />
      </Box>

      <Box id="portfolio" sx={{ flex: 1 }}>
        <Portfolio />
      </Box>
      <Box id="customers">
        <CustomerLogos />
      </Box>
      <Box id="customers">
        <MyApproach />
      </Box>
      <Box id="about">
        <AboutMe />
      </Box>
    </>
  );
};

export default LandingPage;
