import React, { useEffect } from "react";

const ContactUs = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        width: "90%",
        maxWidth: "1200px",
        backgroundColor: "#f5f5f5",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        margin: "auto",
        fontSize: "20px",
        border: "2px solid #271542",
      }}
    >
      <h1
        style={{
          textAlign: "center",
          color: "#271542",
        }}
      >
        Contact Me
      </h1>
      <div data-tf-live="01HY1D5AHHKG3WK2CXAC811BV2"></div>
    </div>
  );
};

export default ContactUs;

// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";

// const ContactForm = () => {
//   const [formSubmitted, setFormSubmitted] = useState(false);
//   const navigate = useNavigate();

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     const form = event.target;
//     const formData = new FormData(form);

//     const data = {
//       name: formData.get("name"),
//       email: formData.get("email"),
//       companyName: formData.get("companyName"),
//       service: formData.get("service"),
//       message: formData.get("message"),
//     };

//     try {
//       const response = await fetch("/.netlify/functions/submit-form", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(data),
//       });
//       if (response.ok) {
//         setFormSubmitted(true);
//         setTimeout(() => {
//           navigate("/");
//         }, 10000); // Redirect to home page after 10 seconds
//       } else {
//         const errorData = await response.json();
//         alert(`Form submission failed: ${errorData.error}`);
//       }
//     } catch (error) {
//       console.error("Form submission error:", error);
//       alert("Form submission failed.");
//     }
//   };

//   return (
//     <div
//       style={{
//         width: "80%",
//         maxWidth: "700px",
//         backgroundColor: "#f5f5f5",
//         padding: "40px",
//         borderRadius: "10px",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//         margin: "auto",
//         fontSize: "16px",
//         border: "2px solid #271542",
//       }}
//     >
//       {formSubmitted ? (
//         <div
//           style={{
//             textAlign: "center",
//             padding: "20px",
//             borderRadius: "8px",
//             boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
//             backgroundColor: "white",
//           }}
//         >
//           <h1>Thank You!</h1>
//           <p>
//             Your message has been received. I&#39;ll get back to you as soon as
//             possible.
//           </p>
//         </div>
//       ) : (
//         <form
//           onSubmit={handleSubmit}
//           style={{ display: "flex", flexDirection: "column" }}
//         >
//           <h2
//             style={{
//               textAlign: "center",
//               marginBottom: "20px",
//               color: "#271542",
//             }}
//           >
//             Contact Me
//           </h2>
//           <p>
//             <label>
//               Full Name:
//               <input
//                 type="text"
//                 name="name"
//                 required
//                 style={{
//                   width: "100%",
//                   padding: "10px",
//                   margin: "10px 0",
//                   border: "1px solid #271542",
//                   borderRadius: "4px",
//                 }}
//               />
//             </label>
//           </p>
//           <p>
//             <label>
//               Email:
//               <input
//                 type="email"
//                 name="email"
//                 required
//                 style={{
//                   width: "100%",
//                   padding: "10px",
//                   margin: "10px 0",
//                   border: "1px solid #271542",
//                   borderRadius: "4px",
//                 }}
//               />
//             </label>
//           </p>
//           <p>
//             <label>
//               Company Name (Optional):
//               <input
//                 type="text"
//                 name="companyName"
//                 style={{
//                   width: "100%",
//                   padding: "10px",
//                   margin: "10px 0",
//                   border: "1px solid #271542",
//                   borderRadius: "4px",
//                 }}
//               />
//             </label>
//           </p>
//           <p>
//             <label>
//               Service:
//               <select
//                 name="service"
//                 required
//                 style={{
//                   width: "100%",
//                   padding: "10px",
//                   margin: "10px 0",
//                   border: "1px solid #271542",
//                   borderRadius: "4px",
//                 }}
//               >
//                 <option value="">Select a Service</option>
//                 <option value="customerJourneyMap">Customer Journey Map</option>
//                 <option value="contentCreation">Content Creation</option>
//                 <option value="technicalWriting">Technical Writing</option>
//                 <option value="consulting">Consulting</option>
//                 <option value="other">Other</option>
//               </select>
//             </label>
//           </p>
//           <p>
//             <label>
//               Message:
//               <textarea
//                 name="message"
//                 required
//                 style={{
//                   width: "100%",
//                   padding: "10px",
//                   margin: "10px 0",
//                   border: "1px solid #271542",
//                   borderRadius: "4px",
//                   height: "150px",
//                 }}
//               ></textarea>
//             </label>
//           </p>
//           <p>
//             <button
//               type="submit"
//               style={{
//                 width: "100%",
//                 padding: "10px",
//                 backgroundColor: "#271542",
//                 color: "#fff",
//                 border: "none",
//                 borderRadius: "4px",
//                 fontSize: "16px",
//                 cursor: "pointer",
//                 transition: "background-color 0.3s",
//               }}
//             >
//               Send
//             </button>
//           </p>
//         </form>
//       )}
//     </div>
//   );
// };

// // Add styles for hover state
// const style = document.createElement("style");
// style.innerHTML = `
//   button[type="submit"]:hover {
//     background-color: #6DACA1 !important;
//   }
// `;
// document.head.appendChild(style);

// export default ContactForm;
