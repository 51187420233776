import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import PortfolioItem from "../Components/PortfolioItem";
import { CaseStudyData } from "../Components/CaseStudyData";

const SampleWork = () => {
  const [selectedTag, setSelectedTag] = useState("All");
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filter = searchParams.get("filter");
    if (filter) {
      setSelectedTag(filter);
    }
    window.scrollTo(0, 0);
  }, [location]);

  const uniqueTags = useMemo(() => {
    const tags = new Set(["All"]);
    CaseStudyData.forEach((study, index) => {
      if (study.tag) {
        const studyTags = Array.isArray(study.tag) ? study.tag : [study.tag];
        studyTags.forEach((tag) => tags.add(tag));
      } else {
        console.warn(`Missing or invalid 'tag' at index ${index}`);
      }
    });
    return Array.from(tags);
  }, []);

  const filteredStudies = useMemo(() => {
    if (selectedTag === "All") {
      return CaseStudyData;
    } else {
      return CaseStudyData.filter((study) => {
        return study.tag.some((t) => t.startsWith(selectedTag));
      });
    }
  }, [selectedTag]);

  return (
    <Box sx={{ p: 4, backgroundColor: "#f5f5f5", minHeight: "500px" }}>
      <Typography variant="h4" align="left" gutterBottom sx={{ mb: 3 }}>
        My Work
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          ml: 1,
          gap: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ whiteSpace: "nowrap" }}>
          Filter:
        </Typography>
        <FormControl sx={{ width: 240 }}>
          <InputLabel id="filter-select-label">Choose a filter</InputLabel>
          <Select
            labelId="filter-select-label"
            value={selectedTag}
            label="Choose a filter"
            onChange={(event) => setSelectedTag(event.target.value)}
            displayEmpty
          >
            {uniqueTags.map((tag) => (
              <MenuItem key={tag} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Grid container spacing={2} justifyContent="center" sx={{ mt: 2 }}>
        {filteredStudies.map((study) => (
          <PortfolioItem key={study.id} {...study} />
        ))}
      </Grid>
    </Box>
  );
};

export default SampleWork;
