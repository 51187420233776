import React from "react";
import { IconButton, Typography, Box } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const BackToTop = () => {
  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box sx={{ position: "absolute", right: 28, bottom: 5 }}>
      <IconButton
        onClick={handleBackToTop}
        sx={{
          color: "grey",
          backgroundColor: "transparent", // Ensure the background is transparent
          "&:hover": {
            backgroundColor: "transparent", // Remove hover effect
            color: "lightgray", // Maintain the icon color on hover
          },
        }}
        aria-label="back to top"
        component="span"
      >
        <ArrowUpwardIcon />
        <Typography>Back to the Top</Typography>
      </IconButton>
    </Box>
  );
};

export default BackToTop;
