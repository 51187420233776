import React from "react";
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import PortfolioItem from "../Components/PortfolioItem";
import BackToTop from "../Components/BackToTop";
import { CaseStudyData } from "../Components/CaseStudyData";
import { useNavigate } from "react-router-dom";

const Portfolio = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  // Array of titles for the projects you want to include
  const includedProjects = [
    "Business Case: Reducing Churn for Company X",
    "Data Analysis: Company ABC Ltd NPS Survey Data",
    "UAT Audit: Plate Up Mobile App",
  ];

  // Filter CaseStudyData based on includedProjects
  const filteredProjects = CaseStudyData.filter((project) =>
    includedProjects.includes(project.title)
  );

  return (
    <Box
      id="portfolio"
      sx={{
        p: 3,
        backgroundColor: "#f5f5f5",
        minHeight: "500px",
        position: "relative",
        paddingBottom: "70px",
      }}
    >
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontSize: isSmallScreen ? "1.5rem" : "3rem" }}
      >
        Portfolio Highlights
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {filteredProjects.map((project) => (
          <PortfolioItem key={project.id} {...project} />
        ))}
      </Grid>
      <Box
        sx={{
          width: "100%", // Full width
          display: "flex",
          justifyContent: "center", // Center horizontally
          mt: 4, // Margin top for spacing
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate("/samplework")}
          sx={{
            backgroundColor: "#271542",
            "&:hover": { backgroundColor: "#6DACA1" },
            fontSize: "1.1rem",
            padding: "20px 30px",
            marginTop: "20px",
          }}
        >
          View All Work
        </Button>
      </Box>
      <BackToTop />
    </Box>
  );
};

export default Portfolio;
