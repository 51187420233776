import React from "react";
import { Grid, Typography, Button, Card, Box, CardMedia } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const PortfolioItem = ({ id, title, description, toolsUsed, imageURL }) => {
  const navigate = useNavigate();

  const handleReadDetailClick = () => {
    navigate(`/case-study/${id}`);
  };

  return (
    <Grid item xs={12} sm={4}>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          m: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          "&:hover": { boxShadow: 6 },
          height: "100%",
          backgroundColor: "#E6E6FA",
        }}
      >
        <Box>
          {/* Display the image */}
          <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
          <CardMedia
            component="img"
            sx={{ objectFit: "cover", objectPosition: "top" }}
            height="180"
            image={imageURL}
            alt={title}
          />
          {/* <Typography
            variant="subtitle1"
            sx={{ color: "#008080", fontWeight: "bold" }}
          >
            # {tag}
          </Typography> */}
          <Typography sx={{ flexGrow: 1, mt: 2, mb: 1, fontSize: "12pt" }}>
            {description}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontSize: "0.875rem", color: "text.secondary" }}
          >
            Tools Used: {toolsUsed.join(", ")}
          </Typography>
        </Box>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={handleReadDetailClick}
          sx={{
            alignSelf: "center",
            mt: 2,
            backgroundColor: "#271542",
            "&:hover": {
              backgroundColor: "#6DACA1",
            },
          }}
        >
          Read Detail
        </Button>
      </Card>
    </Grid>
  );
};

export default PortfolioItem;
