import React from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
import BackToTop from "../Components/BackToTop";

const ServiceItem = ({ title, subTitle, points, filterTag }) => {
  const navigate = useNavigate();

  const handleSeeExamplesClick = () => {
    if (filterTag) {
      navigate(`/samplework?filter=${encodeURIComponent(filterTag)}`);
    } else {
      console.error("Filter tag is missing");
      navigate("/samplework"); // Navigate without a filter if tag is missing
    }
  };

  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Paper
        sx={{
          p: 2,
          mb: 3,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundColor: "white",
          border: "2px solid black",
          color: "rgba(0, 0, 0, 0.87)",
        }}
      >
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
            {title}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {subTitle}
          </Typography>
          <Typography variant="body2" gutterBottom sx={{ paddingTop: "5px" }}>
            This includes:
          </Typography>
          <ul>
            {points.map((point, index) => (
              <li key={index}>
                <Typography
                  variant="body2"
                  sx={{ mb: 0.5, fontWeight: "bold" }}
                >
                  {point.title}
                </Typography>
                <Typography variant="body2">{point.description}</Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Button
          variant="contained"
          endIcon={<ArrowForwardIcon />}
          onClick={handleSeeExamplesClick}
          sx={{
            alignSelf: "flex-start",
            backgroundColor: "#271542",
            "&:hover": {
              backgroundColor: "#6DACA1",
            },
          }}
        >
          See Examples
        </Button>
      </Paper>
    </Grid>
  );
};

const Services = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "#d4af37",
        color: "#333333",
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{ fontSize: isSmallScreen ? "1.5rem" : "3rem" }}
      >
        Services
      </Typography>
      <Typography
        align="center"
        variant="subtitle1"
        sx={{
          paddingBottom: "30px",
          fontSize: isSmallScreen ? "1rem" : "1.25rem",
        }}
      >
        I provide a variety of services to help you create the content you need
        to succeed.
      </Typography>
      <Grid
        container
        spacing={isSmallScreen ? 1 : 2}
        justifyContent="center"
        sx={{ width: "75%", maxWidth: "1200px" }}
      >
        <ServiceItem
          title="Strategy and Optimisation"
          subTitle="We provide comprehensive solutions designed to improve your understanding of customer behaviours and drive effective decision-making. Are you looking for in-depth feedback on your SaaS? Our services include detailed analysis and actionable recommendations to help you achieve your business goals."
          points={[
            {
              title: "Customer Journey Mapping",
              description: (
                <>
                  Map and analyse the customer journey to uncover insights for
                  improvement. Incorporate{" "}
                  <span style={{ color: "#6DACA1" }}>
                    <strong>data analysis</strong>
                  </span>{" "}
                  to drive decisions.
                </>
              ),
            },
            {
              title: "Persona Development",
              description: (
                <>
                  Develop{" "}
                  <span style={{ color: "#6DACA1" }}>
                    <strong>detailed personas</strong>{" "}
                  </span>
                  to better understand your target audience and tailor your
                  marketing strategies effectively.
                </>
              ),
            },
            {
              title: "User Experience Testing",
              description: (
                <>
                  Conduct thorough audits of your digital platforms to identify
                  user experience bottlenecks and functionality issues. Our
                  audit provides detailed insights and{" "}
                  <span style={{ color: "#6DACA1" }}>
                    <strong>actionable recommendations</strong>
                  </span>{" "}
                  to enhance interface usability and overall performance.
                </>
              ),
            },
          ]}
          filterTag="SO"
        />
        <ServiceItem
          title="Content Development & Documentation"
          subTitle="Create high-quality, engaging content tailored to meet your business needs with our Content Development & Documentation services. From user guides to detailed business proposals, we ensure your content is clear, effective, and professionally presented. "
          points={[
            {
              title: "Technical Writing and Documentation",
              description: (
                <>
                  Produce comprehensive documentation and guides that are
                  informative and easy to understand. We cover a range of
                  materials including{" "}
                  <span style={{ color: "#6DACA1" }}>
                    <strong>
                      user guides, standard operating procedures, and business
                      proposals,
                    </strong>
                  </span>{" "}
                  all designed to engage and inform your audience.
                </>
              ),
            },
            {
              title: "Research & Writing",
              description: (
                <>
                  Engage your audience with{" "}
                  <span style={{ color: "#6DACA1" }}>
                    <strong>
                      well-researched articles, insightful blog posts, and
                      compelling case studies
                    </strong>
                  </span>{" "}
                  that highlight key industry trends and innovations.
                </>
              ),
            },
            {
              title: "Ghost Writing",
              description: (
                <>
                  Provide professional ghostwriting services for{" "}
                  <span style={{ color: "#6DACA1" }}>
                    <strong>
                      blogs, articles, books, and thought leadership content
                    </strong>
                  </span>
                  . Tailor the writing to match the client&apos;s voice and
                  brand. Features include in-depth interviews, regular drafts
                  for feedback, and final polished content.
                </>
              ),
            },
          ]}
          filterTag="CDD"
        />
      </Grid>

      <Paper
        sx={{
          mb: 3,
          p: 2,
          backgroundColor: "white",
          border: "2px solid black",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "74%",
          maxWidth: "1200px",
        }}
      >
        <Typography variant="h6" sx={{ flexShrink: 0, fontWeight: "bold" }}>
          Tools I Use:
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            maxWidth: "100%",
            paddingRight: "10px",
            flexWrap: "wrap",
          }}
        >
          {[
            "Python",
            "Excel",
            "Google LightHouse",
            "Markdown",
            "SharePoint",
            "Adobe Illustrator",
            "Confluence",
          ].map((tool) => (
            <Typography key={tool} sx={{}}>
              {tool}
            </Typography>
          ))}
        </Box>
      </Paper>

      <BackToTop />
    </Box>
  );
};

export default Services;
